#sr {
  h1 {
    text-align: center;
  }
  nav {
    margin-top: 35px;
    text-align: center;
  }
  .card-body {
    min-height: 290px;
  }
}

.pagination>li>a, .pagination>li>span {
    color: $green;
    border: 1px solid $grey-border;
}

.pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:hover, .pagination>.active>span:focus {
    background-color: $green;
    border-color: $green;
}
