/*
 * NAV FOR HOME PAGE
 */
#home-header {
  .navbar {
    margin-bottom: 0px;
    padding: 10px 0 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .navbar-inverse {
  	background-color: transparent;
  	border-color: transparent;
    border-radius: 0px;
  }

  .navbar-inverse .navbar-nav>li>a {
    color: #FFF;
    font-weight: 400;
    padding-top: 20px;
    &:hover,
  	&:focus {
  		color: $font-lt;
  	}
  	&:visited {
  		text-decoration: none;
  	}
  }
  .navbar-inverse .navbar-nav>li>a.nav-a {
    padding-top: 8px;
  }
  .navbar-brand {
  	padding: 5px 15px;
  	img {
  		height: 50px;
  	}
  }
  /*
   * MOBILE NAV
   */
  @media only screen and (max-width: 767px) {
  	.navbar-inverse .navbar-toggle {
  	    border-color: #FFF;
  	}
    .navbar-inverse .navbar-toggle .icon-bar {
        background-color: #FFF;
    }
  	.navbar-brand {
  		padding: 1px 15px;
  	}
    .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
        border-color: $green;
        background-color: $green-dk;
    }
  }
}
/*
 * NAV FOR ALL OTHER PAGES
 */
.logo {
  @media #{$media-xs} {
    max-height: 35px;
    margin-top: 5px;
  }
}
.navbar {
  margin-bottom: 0px;
  padding: 10px 0 0px;
}

.navbar-inverse {
	background-color: #FFF;
	border-color: #FFF;
  border-radius: 0px;
}

.navbar-inverse .navbar-nav>li>a {
  color: $font;
  font-weight: 400;
  padding-top: 20px;
  &:hover,
	&:focus {
		color: $font-lt;
	}
	&:visited {
		text-decoration: none;
	}
}
.navbar-inverse .navbar-nav>li>a.nav-a {
  padding-top: 8px;
}
.navbar-brand {
	padding: 5px 15px;
	img {
		height: 50px;
	}
}
/*
 * MOBILE NAV
 */
@media only screen and (max-width: 767px) {
	.navbar-inverse .navbar-toggle {
	    border-color: $font;
      margin-top: 4px;
	}
  .navbar-inverse .navbar-toggle .icon-bar {
      background-color: $font;
  }
	.navbar-brand {
		padding: 1px 15px;
	}
  .navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
      border-color: $grey-border;
  }
}
