/* COLORS */
$green: #009900;
$green-lt: lighten($green,10%);
$green-dk: darken($green,10%);
$orange: #E56B0B;
$orange-dk: darken($orange,6%);
$font: #4A4A4A;
$font-lt: lighten($font,20%);
$grey: #F8F8F8;
$grey-border: #D8D8D8;

// media queries: http://getbootstrap.com/css/#grid-media-queries
$media-xxs: '(max-width:500px)';
$media-xs: '(max-width:767px)';
$media-sm: '(min-width:768px)';
$media-md: '(min-width:992px)';
$media-lg: '(min-width:1200px)';
$media-lg-down: '(max-width:1200px)';
