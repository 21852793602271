thead {
  background-color: $grey;
}

.table-months {
  th {
    font-size: 14px;
    text-align: center;
  }
}

.product {
  font-size: 14px;
  font-weight: 400;
}

.prod-avail {
  background-color: $green;
}
