#search-form {
  .mid-field {
    select {
      -webkit-border-top-left-radius: 0px;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-topleft: 0px;
      -moz-border-radius-bottomleft: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      -webkit-appearance: none;
    }
  }
  @media #{$media-md} {
    .form-control {
      background-color: $grey;
      border: 1px solid $grey-border !important;
    }
    .input-group-addon {
      border-left: 1px solid $grey-border;
      border-top: 1px solid $grey-border;
      border-bottom: 1px solid $grey-border;
    }
    .left-field {
      padding-right: 0px;
      .form-control {
        border-left: 0px;
        border-right: 0px;
        -webkit-border-top-right-radius: 0px;
        -webkit-border-bottom-right-radius: 0px;
        -moz-border-radius-topright: 0px;
        -moz-border-radius-bottomright: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    .mid-field {
      padding-left: 0px;
      padding-right: 0px;
      .input-group-addon {
        border-left: 0px;
        -webkit-border-top-left-radius: 0px;
        -webkit-border-bottom-left-radius: 0px;
        -moz-border-radius-topleft: 0px;
        -moz-border-radius-bottomleft: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
      .form-control {
        border-left: 0px;
      }
      select {
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -webkit-appearance: none;
      }
    }
    .rt-field {
      padding-left: 0px;
    }
  }
  ::placeholder {
    color: $font;
    opacity: 1;
  }
  :-ms-input-placeholder {
      color: $font;
  }
  ::-ms-input-placeholder {
      color: $font;
  }
}

.form-control {
  height: 50px;
}
