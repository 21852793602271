#contact {
  h1 {
    text-align: center;
  }
  ul {
    padding-left: 0px;
  }
  .social-icons {
    margin-top: 25px;
    img {
      width:40px;
      height: auto;
      margin-right: 15px;
    }
  }
  .messages {
    background-color: $green;
    padding: 10px 25px;
    color: #FFF;
  }
  #contact-form {
    margin-top: 15px;
  }
}

#terms {
  h1 {
    text-align: center;
  }
}

#about {
  h1 {
    text-align: center;
  }
}

.side-highlight {
    background-color: $green;
    color: #FFF;
    padding: 5px 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}
