#profile-hero {
  padding: 0;
  background-color: #222;
}

.carousel-control {
  img {
    width: 30px;
  }
}

#farm-info {
  h1 {
    text-align: center;
  }
  .farm-h1 {
    margin-bottom: 25px;
  }
  .lSidebar {
    @media #{$media-sm} {
      margin-top: 20px;
    }
  }
  .pref {
    background-color: $grey;
    padding: 5px 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
  }
  .contact-links {
    margin-bottom: 15px;
    a {
      text-decoration: underline;
    }
    #telShow {
      display: none;
    }
    .clLeft {
      display: inline-block;
      float: left;
      width: 10%;
      @media #{$media-md} {
        width: 7%;
      }
    }
    .clRight {
      display: inline-block;
      width: 89%;
      @media #{$media-md} {
        width: 91%;
      }
      button {
        background-color: transparent;
        border: none;
        padding-left: 0px;
        text-decoration: underline;
      }
      p {margin: 0px;}
    }
    a {
      color: $font;
    }
    span {
      width: 90%;
    }
  }
  .map-embed {
    width: 100%;
    @media #{$media-md} {
      width: 91%;
    }
  }
}
