/*
 * VARIABLES & MIXINS
 */

@import "variables.scss";

/*
 * IMPORTS
 */
@import "base.scss";
@import "nav.scss";
@import "buttons.scss";
@import "forms.scss";
@import "footer.scss";
@import "search-bar-sub-page.scss";
@import "profile-pg.scss";
@import "season-table.scss";
@import "sr.scss";
@import "subPages.scss";
/*
 * GENERAL
 */
 body {
	-webkit-font-smoothing: antialiased;
  color: $font;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 30px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700; margin-bottom: 15px; line-height: 1.2em;
}

h2 {
  font-size: 28px;
}
h3 {
  font-size: 24px;
}

sup {
  top: -1em;
  font-size: 45%;
}

.ftLine {
  background-color: $orange;
  width: 60px;
  height: 4px;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}

a {
  color: $green;
  &:hover,
  &:focus {
    color: $green-lt;
  }
}

.chev-link {
  img {
    margin-left: 5px;
  }
}

.lead {
  font-size: 18px;
}
.small {
  font-size: 11px;
}

.img-resp {max-width: 100%; height: auto; display: block;}

.list-inline {
  ul { list-style-type: none; }
  li { display: inline-block; }
}

section {
  padding: 60px 0px 95px;
}

#home-banner {
  background-color: $green;
  background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #FFF;
	position: relative;
  border-top: 2px solid $green-dk;
  border-bottom: 1px solid $green-dk;
  .main-heading {
    margin-top: 140px;
    text-align: center;
    @media #{$media-xs} {
      margin-top: 70px;
    }
    h1 {
      font-size: 50px;
      color: #FFF;
      @media #{$media-md} {
        font-size: 64px;
      }
      .amp {
        color: $orange;
        @media #{$media-xs} {
          display: block;
        }
      }
    }
  }
  .lead {
    font-size: 22px;
    text-align: center;
    padding-bottom: 160px;
    margin-top: 0px;
  }
  #sign-box {
    position: absolute;
    bottom: -300px;
    margin-bottom: 170px;
    background: #FFF;
    box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.13);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    padding: 20px 20px 15px;
    width: 92%;
    color: $font;
    @media #{$media-sm} {
      bottom: -260px;
      width: 96%;
    }
    @media #{$media-md} {
      bottom: -240px;
      width: 100%;
    }
    .form-title {
      margin: 0 0 15px;
      text-align: center;
      font-size: 22px;
    }
    .small {
      text-align: center;
      line-height: 15px;
    }
  }
}

#grey-bg {
  background-color: $grey;
  border-bottom: 1px solid $grey-border;
  padding: 160px 0 120px;
  @media #{$media-md} {
    padding: 120px 0 120px;
  }
  h3 {
    text-align: center;
  }
  .card-body {
    min-height: 228px;
  }
}

.checks-row {
  li {
    margin-right: 15px;
  }
  img {
    margin-right: 6px;
  }
}

#cta-fff {
  text-align: center;
  h4 {
    font-size: 26px;
  }
  .fresh-food {
    margin: 30px 0;
    img {
      display: block;
      margin: 0 auto;
    }
  }
}

#cta {
  text-align: center;
  background-color: $grey;
  border-bottom: 1px solid $grey-border;
  border-top: 1px solid $grey-border;
  h5 {
    font-size: 26px;
  }
}

.farm-card {
  box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.13);
  margin-top: 25px;
  .card-body {
    padding: 10px 25px 25px;
    background-color: #FFF;
  }
}
