a.btn-wrap {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.ft-btn {
  background: $orange;
  box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.13);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: #FFF;
  padding: 10px;
  &:hover,
  &:focus {
    color: #FFF;
    background: $orange-dk;
  }
}

.search-btn {
  min-height: 50px;
  @media #{$media-md} {
    -webkit-border-top-left-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -1px;
  }
}

#home-header {
  .nav-btn {
    background: transparent;
    color: #FFF;
    border: 1px solid #FFF;
    font-weight: 700;
    box-shadow: none;
    font-size: 16px;
    &:hover,
    &:focus {
      color: #FFF;
      background: $green;
    }
  }
}

.nav-btn {
  background: $green;
  color: #FFF;
  border: 1px solid $green;
  font-weight: 700;
  box-shadow: none;
  font-size: 16px;
  &:hover,
  &:focus {
    color: #FFF;
    background: $green-dk;
  }
}
