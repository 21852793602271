footer {
  text-align: center;
  padding: 25px 0;
  background-color: #222;
  .footer-links {
    margin: 25px 0 0px;
  }
  a {
    color: $grey;
  }
  .footer-nav a {
    margin: 0 10px;
  }
  .footer-contact a {
    font-size: 14px;
  }
  span {
    font-size: 12px;
    color: $font-lt;
    display: block;
  }
}
