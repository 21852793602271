.search-sub {
  background-color: $grey;
  border-bottom: 1px solid $grey-border;
  border-top: 1px solid $grey-border;
  padding: 25px 0;
  .form-title {
    margin: 0 0 15px;
    text-align: center;
    font-size: 22px;
  }
  #search-form {
    .form-control {
      background-color: #FFF;
    }
  }
}
